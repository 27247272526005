<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          lg="6"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>
        <!-- New Order -->
        <b-col
          lg="6"
          sm="12"
          class="d-flex align-items-right justify-content-end mb-1 mb-md-0 topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              @click="$router.push({ name: 'add-purchaseOrders' })"
            >
              Nueva orden de compra
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr style="margin-top: 3px">
      <!-- Filters -->
      <b-row>
        <b-col
          lg="3"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Tipo de pago"
          >
            <v-select
              label="name"
              class="d-inline-block ml-50 mr-1"
              style="width: 218px"
              :options="paymentTypes"
              v-model="filtersArray[0]"
              :reduce="name => name.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="3"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Filtrar empresas"
          >
            <v-select
              class="d-inline-block ml-50 mr-1"
              style="width: 218px"
              :options="companies"
              v-model="filtersArray[1]"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="3"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Estimado de entrega"
          >
            <flat-pickr
              class="form-control d-inline-block ml-50 mr-1"
              :config="{ dateFormat: 'd-m-Y', mode: 'range' }"
              v-model="filtersArray[2]"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="3"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Sólo órdenes activas: "
          >
            <b-form-checkbox
              :value="true"
              :unchecked-value="false"
              switch
              v-model="filtersArray[3]"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- :items="fetchInvoices" -->
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <div id="main-table">
        <b-table
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="filteredData"
          @row-clicked="$router.push({ name: 'preview-purchaseOrders', params: { id: $event.idOrder }})"
          ref="refInvoiceListTable"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No hay registros"
          class="position-relative"
        >

          <!-- Column: IdOrder -->
          <template #cell(id)="data">
            <div class="font-weight-bold">
              #{{ data.item.idOrder }}
            </div>
          </template>

          <!-- Column: Shipping Status -->
          <template #cell(shippingStatus)="data">
            <template v-if="data.item.shippingStatus === 'ENTREGADO'">
              <b-badge
                pill
                variant="light-success"
              >
                Entregado
              </b-badge>
            </template>
            <template v-else-if="data.item.shippingStatus === 'EN CURSO'">
              <b-badge
                pill
                variant="light-warning"
              >
                En curso
              </b-badge>
            </template>
            <template v-else-if="data.item.shippingStatus === 'SIN ENVIAR'">
              <b-badge
                pill
                variant="light-danger"
              >
                Sin enviar
              </b-badge>
            </template>
          </template>

          <!-- Column: Payment Status -->
          <template #cell(paymentStatus)="data">
            <template v-if="data.item.paymentStatus === 'PAGADO'">
              <b-badge
                pill
                variant="light-success"
              >
                Pagado
              </b-badge>
            </template>
            <template v-else-if="data.item.paymentStatus === 'PARCIALMENTE PAGADO'">
              <b-badge
                pill
                variant="light-warning"
              >
                Parcial
              </b-badge>
            </template>
            <template v-else-if="data.item.paymentStatus === 'SIN PAGAR'">
              <b-badge
                pill
                variant="light-danger"
              >
                Sin pagar
              </b-badge>
            </template>
          </template>

          <!-- Column: Creation Date -->
          <template #cell(creationDate)="data">
            {{ $moment.unix(data.item.creationDate/1000).format('DD/MM/YYYY') }}
          </template>

          <!-- Column: Estimated Date -->
          <template #cell(estimatedDate)="data">
            {{ $moment.unix(data.item.estimatedDate/1000).format('DD/MM/YYYY') }}
          </template>

          <!-- Column: Estimated Payment -->
          <template #cell(deadlinePayment)="data">
            <div v-if="data.item.deadlinePayment">
              {{ $moment.unix(data.item.deadlinePayment/1000).format('DD/MM/YYYY') }}
            </div>
            <div v-else>
              N/A
            </div>
          </template>

        </b-table>
      </div>
    </b-overlay>

    <div class="mx-2 mb-2" style="margin-top: 1em">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
        <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/customHelios.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    ToastificationContent,
    BOverlay,
    vSelect,
    BFormGroup,
    BFormCheckbox,
    flatPickr
  },
  data() {
    return {
      // Table
      tableColumns: [
        { key: 'id', label: 'ID', sortable: false, thStyle: {width: '1em'} },
        { key: 'companyName', label: 'Empresa', sortable: true },
        { key: 'shippingStatus', label: 'Estatus del envío', sortable: true },
        { key: 'paymentStatus', label: 'Estatus del pago', sortable: true },
        { key: 'creationDate', label: 'Fecha de creación', sortable: true },
        { key: 'estimatedDate', label: 'Estimado de entrega', sortable: true },
        { key: 'deadlinePayment', label: 'Estimado de pago', sortable: true },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'status',
      sortDesc: false,
      sortDirection: 'asc',

      // Filters
      filtersArray: [null, null, null, false],
      filter: null,
      filterOn: [],
      onFiltered: [],
      companies: [],

      // Data
      data: [],
      paymentTypes: [],

      // Props
      tableOverlay: true,
    }
  },
  created() {
    this.getPurchaseOrders();
    this.getStatus();
  },
  computed: {
    filteredData() {

      // Ignorar si el filtro es null
      if (this.filtersArray[0] == null)
        this.filtersArray[0] = '';
      if (this.filtersArray[1] == null)
        this.filtersArray[1] = '';
      if (this.filtersArray[2] == null)
        this.filtersArray[2] = '';

      return this.data.filter(order => {
        return (order.companyName.includes(this.filtersArray[1]) &&
                String(order.paymentType).includes(String(this.filtersArray[0])) &&
                this.$moment(this.$moment.unix(order.estimatedDate / 1000).format('DD/MM/YYYY'), 'DD/MM/YYYY').isBetween(
                  this.filtersArray[2].slice(0, 10) === '' ? this.$moment('01/01/0001', 'DD/MM/YYYY') : this.$moment(this.filtersArray[2].slice(0, 10), 'DD/MM/YYYY'), 
                  this.filtersArray[2].slice(13, 23) === '' ? undefined : this.$moment(this.filtersArray[2].slice(13, 23), 'DD/MM/YYYY'), null, '[]') &&
               (!(this.filtersArray[3]) || (order.paymentStatus !== 'PAGADO' || order.shippingStatus !== 'ENTREGADO')));
      });
    },
  },
  methods: {
    getPurchaseOrders() {
      this.$axios.get(this.$services + 'orders/get_purchase_orders').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          this.data.forEach(order => {
            this.companies.push(order.companyName);
          });
          this.companies = [...new Set(this.companies)];
          this.totalRows = res.data.data.length;
        }
        this.tableOverlay = false;
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    getStatus() {
      this.$axios.get(this.$services + 'general/get_status', {
        params: {
          statusName: 'PAYMENT'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.paymentTypes = res.data.data;
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
  },
}
</script>
